import store from "@/store"
import CacheKey from "@/config/cache-key"
import { joinRoom, leaveRoom, getRoomDetails, getImResource, getRoomIdByKid } from "@/api/room"
import { useUserStoreHook } from "./user";
import { useSocketStoreHook } from "./socket";
import { useRouter } from "vue-router"
import { usePkpStore } from "./pkp";
import { usePkpResultStore } from "./pkpresult";
import { SuperTypeImg } from "@/config/menu-config"
import { merge, cloneDeep } from 'lodash-es'
import { useAudioStore } from "@/store/modules/audio"
import { dynamicPublicForCdnUrl, timer16ByWorker, setTransitionEnd, getStorage, setStorage, delStorage } from "@/utils"
import { storeToRefs } from "pinia";
import { nextTick, shallowRef } from "vue";
import { ShowToast } from "@/libs/components/Notice";


const PaddingRomMargin = 0, // 桌子两侧的内边距
    SeatDirection = ["top", "left", "bottom", "right"];

export const ServerPosition = {
    1: "top",
    2: "left",
    3: "right",
    4: "bottom"
}

export const ClientPosition = {
    "top": 1,  
    "left": 2,  
    "right": 3,  
    "bottom": 4
}

const LeaveDeskData = {
    uid: -1,
    ServerInfo: null,
    IsSitDown: false,
    score: 0,
    nickName: "",
    avatarImg: "",
    IsShowOffLine: false,
}

const DefaultPlayerArray = [
    {
        position: "top",
        // IsSitDown
        // nickName: "junge",
        // score: 12,
        // avatarImg: dynamicPublicForCdnUrl("/image/avatar1.jpg"),
    },
    {
        position: "left",
    },
    {
        position: "right",
    },
    {
        position: "bottom",
    },
]

let DunsClear;

export const useRoomStore = defineStore("room", () => {
    const router = useRouter();
    const userStore = useUserStoreHook(),
        { UserInfo } = storeToRefs(userStore),
        { UpdateUserInfo } = userStore;

    const audioStore = useAudioStore();

    const socketStore = useSocketStoreHook(),
        pkpStore = usePkpStore();

    const pkpResultStore = usePkpResultStore(),
        { ShootResult, PlayerTotalSource } = storeToRefs(pkpResultStore);

    const RoomInfo = ref(getStorage(CacheKey.ROOM_INFO) || {});

    const RoomGameStatus = ref(0);
    const RoomGameRound = ref(0);
    const RoomUserNumber = ref(0); // 在线房间人数

    const LeaveUser = ref(null),
        WatchUser = ref(null);
    
    const PlayerArray = ref(cloneDeep(DefaultPlayerArray)),
        PlayerSeatPositions = shallowRef(null);

    // 组件元素
    const oPlayerDesk = shallowRef(null),
        oPlayerRefs = shallowRef([]);// 玩家组件元素;

    const SpecialAnimationRef = ref(null),
        HomeRunAnimationRef = ref(null),
        ScoreRef = ref(null),
        VsPkpTextRef = ref(null);

    const ImResourceTxtList = ref(null),
    ImResourceEmoteList = ref(null);

    const IsRunPkpResultAnimate = ref(false);
    const IsShowSetUserActionComp = ref(false);
    const IsShowPkResultDialog = ref(false);

    const IsUseG = ref(false);
    const IsInJoinRoom = ref(false);

    const CacheAnimationJoinDesk = shallowRef([])

    const IsUserInDeskPlayer = computed(() => {
        return PlayerArray.value.some((item) => item.uid === UserInfo.value.uid )
    })

    const CacheInitPlayerPosition = shallowRef([]);


    function CacheInitPlayerPositionFn(){
        console.log("没有坐标信息兜底处理", CacheInitPlayerPosition.value);
        if(CacheInitPlayerPosition.value?.length > 0){
            CacheInitPlayerPosition.value.forEach((position) => {
                InitPlayerPosition(position)
            })
            CacheInitPlayerPosition.value = [];
        }
    }

    function AnimationEndJoinDesk(){
        CacheAnimationJoinDesk.value.forEach((link_msg) => {
            const { room_info, position, user_info, score } = link_msg;
            const { user_decorate } = user_info;

            const idx = FindIdxPlayerByServerPosition(position)
            if(idx === -1){ // 位置上已经有人了
                console.warn("join_room_player 监听，位置上已经有人了，不需要上桌", opt);
                return;
            }

            RenderJoinDesk({
                idx,
                position,
            }, {
                uid: user_info.user_account.uid,
                ServerInfo: user_info,
                IsSitDown: true,
                score: score || 0,  // 分数  服务器没有给分数
                nickName: user_decorate.nickname,
                avatarImg: user_decorate.profile,
            });
        })

        CacheAnimationJoinDesk.value = [];
    }

    function PushCacheAnimationJoinDesk(value){
        CacheAnimationJoinDesk.value.push(value);
    }

    function PopCacheAnimationJoinDesk(value){
        const res = CacheAnimationJoinDesk.value.filter((item) => {
            return item.position != value.position
        });
        CacheAnimationJoinDesk.value = res;
    }

    function SetIsShowUserActionComp(value){
        value && GetRoomInfo();
        IsShowSetUserActionComp.value = value;
    }

    function JoinRoom(params){
        return new Promise(async (resolve, reject) => {
            const { uid, room_id } = params;

            if(IsInJoinRoom.value === true){
                console.warn("已经加入过房间了", IsInJoinRoom)
                resolve({
                    code: 0,
                    msg: "已经加入过房间了",
                })
                return
            }

            const res = await joinRoom({
                uid, room_id
            }).catch((err) => reject(err));

            if(!res || res?.code !== 0) {
                RoomInfo.value = {};
                delStorage(CacheKey.ROOM_INFO);
                console.error("JoinRoom error: ", res);

                reject(res)
                return null;
            }

            IsInJoinRoom.value = true;
            _SetRoomInfo({
                id: room_id,
            });

            resolve(res)
            return res;
        })   
    }

    function GetRoomIdByKid(){

        return new Promise(async (resolve, reject) => {
            if (RoomInfo.value.kid === undefined || RoomInfo.value.kid === null){
                console.error("kid is null", RoomInfo.value)
                reject("没有房间号")
                return
            }

            const res = await getRoomIdByKid({
                room_kid: RoomInfo.value.kid
            })
    
            if(res.code != 0){
                ShowToast("服务异常", 3000)
                reject(res)
                return
            }
    
            _SetRoomInfo({
                id: res.data.room_id,
                kid: res.data.room_kid,
            });
    
            resolve(res)
        })
    }

    function InitRoomData(){
        RoomInfo.value = {};
        PlayerArray.value = cloneDeep(DefaultPlayerArray);
        oPlayerRefs.value.forEach((player, index) => {
            player?.setIsShowReadyPlayBtn(false);
            player?.setIsSitDown(false);
            player.oNoPlayerSeatStatusChange("sitdown");
        });
        oPlayerRefs.value = [];
        IsInJoinRoom.value = false;
        CacheInitPlayerPosition.value = [];
        console.log("有信息处理 初始化");
        
        delStorage(CacheKey.ROOM_INFO);
    }

    async function LeaveRoom(){
        const res = await leaveRoom({
            uid: UserInfo.value?.uid, 
            room_id: RoomInfo.value?.id
        }).catch((err) => {
            alert("离开房间失败");
            console.error("LeaveRoom Error: ", err);
        });

        
        InitRoomData()
        socketStore.ClearKeepLive();

        return res;
    }

    function RenderJoinDesk(opt, value){
        const { idx, position: SocketPosition, otherSeatStatus, status = null, is_online = true } = opt;
        _SetPlayerArrayInfo(idx, value);

        const oPlayer = FindoPlayer({
            uid: UserInfo.value.uid
        });

        oPlayerRefs.value.forEach((player, index) => {
            const playerInfo = player.getPlayerInfo();
            
            if(playerInfo.position === ServerPosition[SocketPosition]){
                player?.setIsShowReadyPlayBtn(false);
                player?.setIsSitDown(true);
                player.setAvatarImg(value.avatarImg);
                player.setIsShowOffLine(value.uid === 0 ? false : is_online ? false : true);
                player.setGameScore(value.score);

                (status !== null) && player?.setGamePlayerStatus(status);
                ([2, 3, 4].includes(status)) && player?.setIsShowOffLine(false);
                console.log("上桌", player, PlayerArray);
                console.log("UserInfo更新", value.uid, UserInfo.value.uid);
                
                if(value.uid === UserInfo.value.uid){ // 如果是自己，更新信息
                    UpdateUserInfo({
                        status: status,
                    })
                }
            } else {
                if(!player.uid || player.uid == 0 || player.uid == -1){
                    if(oPlayer){ // 如果玩家是在座位上的
                        otherSeatStatus && player.oNoPlayerSeatStatusChange(otherSeatStatus);
                    } else { // 如果是游客
                        player.oNoPlayerSeatStatusChange("sitdown");
                    }
                }
            }
        });
    }

    function RenderLeaveDesk(opt, value = null){
        const { idx, position, user_decorate } = opt;
        let oPlayer;
        oPlayerRefs.value.forEach((player, index) => {
            const targeInfo = player.getPlayerInfo()
            if(targeInfo.uid === user_decorate?.uid){ // 更改其他座位状态
                oPlayer = player;
            }
            
            if(user_decorate?.uid === UserInfo.value.uid){
                player.oNoPlayerSeatStatusChange("sitdown");
            }
        });
        
        _SetPlayerArrayInfo(idx, (value || cloneDeep(LeaveDeskData)));
        oPlayer?.setIsShowReadyPlayBtn(false);
        oPlayer?.setIsSitDown(false);
        oPlayer?.setGamePlayerStatus(0);
        oPlayer?.setAvatarImg("");

        console.log("下桌", oPlayer, PlayerArray);
    }

    function PlayerSendTxtMsg(opt){
        const { uid, txt, audioUrl } = opt;
        const oPlayer = FindoPlayer({
            uid
        });

        if(oPlayer){
            oPlayer.sendTxtMsg(txt);
            audioUrl && audioStore.IMAudioBGM(audioUrl);
        }
    }

    function PlayerSendEmoteMsg(opt){
        const { uid, imgUrl } = opt;
        const oPlayer = FindoPlayer({
            uid
        });

        if(oPlayer){
            oPlayer.sendEmoteMsg(imgUrl);
        }
    }

    async function GetRoomInfo() {
        if(RoomInfo.value?.id == undefined || RoomInfo.value?.id === null){
            console.error("没有房间信息")
            return
        }

        const res = await getRoomDetails({
            uid: UserInfo.value.uid,
            room_id: RoomInfo.value.id,
        }).catch((err) => {})

        if(!res || res?.code !== 0){
            console.error("获取房间接口有误")
            return
        }

        console.log("获取房间信息", res);

        Object.values(res.data.players).forEach((item) => {
            const targetIdx = PlayerArray.value.findIndex((player) => {
                return player.uid === item.uid
            })
            
            if(targetIdx !== -1){
                PlayerArray.value[targetIdx].score = item.score;
            }
        });


        Object.values(res.data.players).forEach((item) => {
            if(item.score !== 0){
                const oPlayer = oPlayerRefs.value.find((player) => {
                    const playerInfo = player.getPlayerInfo();
                    playerInfo.uid === item.uid;
                })

                if(oPlayer){
                    oPlayer.setGameScore(item.score);
                }
            }
        })

        WatchUser.value = res.data.watch_user;
        LeaveUser.value = res.data.leave_user;
        return res;
    }

    function UpdatePlayerArrayInfo(opt){ // 更新所有玩家信息
        const { players, now_process } = opt;
        if(typeof players !== "object" || players === null){
            console.error("房间玩家信息不是个对象")
            return
        }

        const ServerPlayers = Object.values(players);
        PlayerArray.value.forEach((player, idx) => {
            const findIdx = ServerPlayers.findIndex((item) => ServerPosition[item.position] === player.position);
            const targetInfo = ServerPlayers[findIdx];

            if(findIdx !== -1 && targetInfo.uid !== 0){
                const { user_info } = targetInfo;

                RenderJoinDesk({
                    idx,
                    position: targetInfo.position,
                    otherSeatStatus: [2, 3, 4].includes(targetInfo.status) ? "invite" : "sitdown",
                    status: targetInfo.status,
                    is_online: targetInfo.is_online
                }, {
                    uid: targetInfo.uid,
                    ServerInfo: targetInfo,
                    IsSitDown: (targetInfo.uid === UserInfo.value.uid) ? false : true,
                    score: targetInfo.score,
                    nickName: user_info?.user_decorate?.nickname,
                    avatarImg: user_info?.user_decorate?.profile,
                    IsShowReadyPlayBtn: (targetInfo?.status === 4 ) ? true : false, // 4阶段刷新
                    IsShowWaitingPkp: (targetInfo?.status === 4 ) ? false : true,
                });

            } else {
                PlayerArray.value[idx] = {
                    ...PlayerArray.value[idx],
                    ...LeaveDeskData
                };
            }
        })

        const IsFlag = PlayerSeatPositions.value === null;
        ServerPlayers.find((item) => {
            console.log("初始化座位", item, item.uid, UserInfo.value.uid);
            if(item.uid === UserInfo.value.uid
                && [2, 3, 4, 5].includes(item.status)
            ){
                if(IsFlag){
                    console.log("没有坐标信息", PlayerSeatPositions.value, CacheInitPlayerPosition.value);
                    if(CacheInitPlayerPosition.value?.length <= 0){
                        CacheInitPlayerPosition.value.push(ServerPosition[item.position]);
                    }
                } else {
                    console.log("有坐标信息", PlayerSeatPositions.value);
                    CacheInitPlayerPosition.value = [];
                    InitPlayerPosition(ServerPosition[item.position]);
                }
            }
        });

        console.log("玩家信息", PlayerArray.value);
        
        // PlayerArray.value = newPlayerArray;
        // setStorage(CacheKey.PLAYER_ARRAY, PlayerArray.value)
    }
    
    function PlayerOnLine(opt) {
        const { user_info } = opt;
        
        const oPlayer = FindoPlayer({
            uid: user_info.user_account.uid
        })

        if(oPlayer){
            oPlayer.setIsShowOffLine(false);
        }
    }

    function PlayerOffLine(opt){
        const { user_info, position: SocketPosition } = opt;

        const oPlayer = FindoPlayer({
            uid: user_info.user_account.uid
        })

        if(oPlayer){
            oPlayer.setIsShowOffLine(true);
        }

    }

    function FindoPlayer(opt){
        const { uid, position } = opt;
        return oPlayerRefs.value.find((player) => {
            const playerInfo = player.getPlayerInfo();

            console.log("🚀 ~ returnoPlayerRefs.value.find ~ playerInfo:", playerInfo)
            if(playerInfo.uid === uid){
                return player;
            }
        });
    }

    function PlayerSeatStatusChange(opt, value){
        const { idx, position } = opt;
        RenderJoinDesk({
            idx,
            position,
            otherSeatStatus: "invite",
            is_online: true,
        }, { ...value, 
            IsSitDown: true, // 是否坐下  
            score: UserInfo.value.score,
            nickName: UserInfo.value.nickName,
            avatarImg: UserInfo.value.avatarImg
        });

        nextTick(() => {
            UpdateUserInfo({
                SeatInfo: {
                    idx, position, 
                    room_id: RoomInfo.value.id,
                    room_kid: RoomInfo.value.kid
                },
                status: 0,
            })
        })
    }
    
    async function GetImResourceTxtData(){
        if(ImResourceTxtList.value === null) {
            const res = await getImResource({
                "uid": UserInfo.value.uid,
                "type": 1
            }).catch(() => {})

            if(res?.code != 0) {
                ShowToast("服务异常", 3000)
                return;
            }

            ImResourceTxtList.value = res.data;
        }

        return ImResourceTxtList.value;
    }
    
    async function GetImResourceEmoteData(){
        if(ImResourceEmoteList.value === null) {
            const res = await getImResource({
                "uid": UserInfo.value.uid,
                "type": 2
            }).catch(() => {})

            if(res?.code != 0) {
                ShowToast("服务异常", 3000)
                return;
            }

            ImResourceEmoteList.value = res.data;
        }

        return ImResourceEmoteList.value;
    }

    function ComputedSeatPositions(oPlayerBox){
        if(PlayerSeatPositions.value === null){
            PlayerSeatPositions.value = computedPosition({
                bgElem: oPlayerDesk.value, 
                playerElem: oPlayerBox,
            });
        }
    }

    function PlayerPosRender(idx){
        if(PlayerSeatPositions.value === null){
            console.warn("没有坐标")
            return;
        }

        const player = oPlayerRefs.value[idx];
        const { position, oPlayerBoxEl } = player;
        const { left, top } = PlayerSeatPositions.value[position];

        oPlayerBoxEl.style.cssText = `
            left: ${ left }px;
            top: ${ top }px;
        `;
    }

    function InitPlayerPosition(position){
        switch(position){
            case "top":
                AnimationSwapPosition(true);
                break;
            case "left":
                AnimationRotateClockwise(true);
                break;
            case "right":
                AnimationRotateAntiClockwise(true);
                break;
            default:
                break;
        }
    }

    function FindIdxPlayerByServerPosition(position){
        console.log("PlayerArray", PlayerArray);
        return PlayerArray.value.findIndex((item) => item.position === ServerPosition[position])
    }

    function FindIdxPlayerByUid(uid){
        return PlayerArray.value.findIndex((item) => item.uid === uid)
    }

    function SetRoomUserNumber(value){
        RoomUserNumber.value = value;
    }

    function _SetPlayerArrayInfo(idx, value){
        if(typeof idx != "number" || idx < 0) {
            console.error("参数不正确", idx, value);
            return;
        }

        const UserInfo = merge(PlayerArray.value[idx], value);
        PlayerArray.value[idx] = UserInfo;
    }

    function SetIsShowPkResultDialog(value){
        if(value === false){
            pkpResultStore.InitData()
        }
        IsShowPkResultDialog.value = value;
    }

    function _SetRoomInfo(value){ 
        console.log("🚀 ~ _SetRoomInfo ~ value:", value)
        // 房间号不同时 更新信息
        // if(RoomInfo.value?.id != value.id){
            RoomInfo.value = merge(RoomInfo.value, value);
            setStorage(CacheKey.ROOM_INFO, RoomInfo.value);
        // }
    }

    function _SetPlayerRefs(idx, value){
        oPlayerRefs.value[idx] = value;
    }

    function SetRoomGameRound(value){
        RoomGameRound.value = value;
    }

    function SetRoomGameStatus(value){
        RoomGameStatus.value = value;
    }

    function SetIsUseG(value){
        IsUseG.value = value;
    }

    function AnimationRotatePkResult(){
        return new Promise(async (resolve) => {
            IsRunPkpResultAnimate.value = true;

            const SpecialCard = [];
            
            const HomeRunCard = [];

            const TouDunRotate = [],
                ZhongDunRotate = [],
                DiDunRotate = [];

                DunsClear = [];

            const ExpandPromise = [...oPlayerRefs.value].reduce((origin, player) => {
                const pkResultData = player.getPkpResultData().value; // 过滤特殊牌
                console.log("🚀 ~ ExpandPromise ~ pkResultData:", pkResultData)

                const pkResultRef = player.getPkpResultRef().value;
                
                if(pkResultRef && pkResultData && pkResultData?.super_type === -1){
                    pkResultRef.isShow(true);
                    origin.push(pkResultRef.Expand());

                    TouDunRotate.push(pkResultRef.TouRotate);
                    ZhongDunRotate.push(pkResultRef.ZhongRotate);
                    DiDunRotate.push(pkResultRef.DiRotate);
                    DunsClear.push(pkResultRef.Clear);
                } else if(pkResultData && pkResultData?.super_type !== -1) {
                    pkResultRef.isShow(true);
                    origin.push(pkResultRef.Expand());
                    DunsClear.push(pkResultRef.Clear);
                    SpecialCard.push({ player, pkResultData });
                }

                if(pkResultData && pkResultData.is_all_shoot === 1){ // 全垒打
                    HomeRunCard.push({ player, pkResultData })
                }

                return origin;
            }, []);

            VsPkpTextRef.value.Open().then(() => {
                setTimeout(() => {
                    VsPkpTextRef.value.Close()
                }, 600)
            })
            
            const PromiseFn = [...ExpandPromise];
            console.log("🚀 ~ returnnewPromise ~ PromiseFn:", PromiseFn)

            await Promise.allSettled(PromiseFn);
            // ScoreRef
            // touDunOpen, zhongDunOpen, diDunOpen

            const AnimationArr = [
                ...TouDunRotate, 
                ScoreRef.value.touDunOpen,
                ...ZhongDunRotate, 
                ScoreRef.value.zhongDunOpen,
                ...DiDunRotate,
                ScoreRef.value.diDunOpen
            ];
            console.log("🚀 ~ returnnewPromise ~ AnimationArr:", AnimationArr)
            console.log("cleart DunsClear", DunsClear);
            
            for(let i = 0; i < AnimationArr.length; i++){
                const animateFn = AnimationArr[i];
                await animateFn()
            }


            // 翻转动画结束
            // 打枪
            await ShootTingAnimation()

            // 特殊牌
            if(SpecialCard.length > 0) {
                await SpecialAnimation(SpecialCard)
                await new Promise((resolve) => {
                    const stopTimer = timer16ByWorker(() => {
                        stopTimer()
                        resolve(true)
                    }, 60)
                })
            }

            // 全垒打
            if(HomeRunCard.length > 0 ) {
                await HomeRunAnimation(HomeRunCard);
                await new Promise((resolve) => {
                    const stopTimer = timer16ByWorker(() => {
                        stopTimer()
                        resolve(true)
                    }, 60)
                })
            }

            oPlayerRefs.value.forEach((player) => {
                player.setGamePlayerStatus(5);
            });
            SetRoomGameStatus(5);
            UpdateUserInfo({
                status: 5,
            })


            // 收尾
            console.log("收尾清空", DunsClear);
            DunsClear.forEach((fn) => fn())
            ScoreRef.value.clear();

            console.log(`当前盘数:${ RoomGameRound.value + '/' +RoomInfo.value?.room_introduction?.number_of_stages }`, RoomInfo.value);
            if(RoomGameRound.value == RoomInfo.value?.room_introduction?.number_of_stages){ // 游戏结束
                console.log("游戏结束");
                const roomID = RoomInfo.value.id;
                InitRoomData();
                router.replace({ path: "/over", query: {
                    kid: Number(roomID)
                }});
                return;
            } else {
                SetIsShowPkResultDialog(true);

                [...oPlayerRefs.value].forEach((player) => {
                    const playerInfo = player.getPlayerInfo()
                    
                    if(player.IsShowOffLine === true){
                        console.log("踢出用户", {
                            idx: player.idx,
                            user_decorate: {
                                uid: playerInfo.uid
                            }
                        });
                        
                        // 踢出离线状态的用户
                        RenderLeaveDesk({
                            idx: player.idx,
                            user_decorate: {
                                uid: playerInfo.uid
                            }
                        });
                    }

                    if(player.IsAnimationReady){
                        player.setGamePlayerStatus(1);
                        player.setIsAnimationReady(false);
                    }

                    const score = PlayerTotalSource.value?.[playerInfo.uid];
                    if(score){
                        player.setGameScore(score);
                    }
                });

                // 动画阶段缓存的上桌坐上
                AnimationEndJoinDesk();
            }

            await new Promise((resolve) => {
                setTimeout(() => {
                    IsRunPkpResultAnimate.value = false;
                    resolve(true)
                }, 1000)
            })

            resolve(true);
        })
    }

    function ShootTingAnimation(){
        return new Promise(async (resolve) => {
            console.log("打枪🚀 ~ returnnewPromise ~ ShootResult.value:", ShootResult.value)
            const len = ShootResult.value.length;
            for(let i = 0; i < len; i ++){
                const item = ShootResult.value[i];
                console.log("🚀 ~ returnnewPromise ~ item:", item)
                const { win_number, lose_number, shoot_number } = item;

                const [ winShootRef, loseShootRef ] = FindTargetPlayer([win_number, lose_number])
                if(winShootRef === null || loseShootRef === null || shoot_number === 0 ){
                    continue
                }

                console.log("枪手winShootRef, loseShootRef", winShootRef, loseShootRef);
                
                console.log("打枪动画");
                await winShootRef.RenWuShooting()
                console.log("打枪动画结束-中枪动画");
                await loseShootRef.DanKongShooting()
                console.log("中枪动画结束");
                

                await new Promise(async(resolve) => {
                    // setTimeout(() => {
                    //     winShootRef.CloseDankong()
                    //     loseShootRef.CloseDankong()
                    //     resolve(true);
                    // }, 1200)

                    const stopTimer = timer16ByWorker(() => {
                        stopTimer()
                        winShootRef.CloseDankong()
                        loseShootRef.CloseDankong()
                        resolve(true);
                    }, 0.5 * 60)
                })
            }
            resolve(true)
        });

        function FindTargetPlayer(opt){
            const [ win, lose ] = opt
            const resultPlayer = [null, null];

            for(let i = 0; i < oPlayerRefs.value.length; i++ ){
                if(resultPlayer[0] && resultPlayer[1]) break;

                const oPlayer = oPlayerRefs.value[i];
                const targetInfo = oPlayer.getPlayerInfo();

                console.log("🚀 ~ FindTargetPlayer ~ targetInfo:win", targetInfo.position === ServerPosition[win], targetInfo, win, ServerPosition[win])
                if(targetInfo.position === ServerPosition[win]){
                    resultPlayer[0] = oPlayer.getShootingRef().value;
                    console.log("🚀 ~ FindTargetPlayer ~ oPlayer:win", oPlayer)
                    continue;
                }

                console.log("🚀 ~ FindTargetPlayer ~ targetInfo:lose", targetInfo.position === ServerPosition[lose], targetInfo, lose, ServerPosition[lose])
                if(targetInfo.position === ServerPosition[lose]) {
                    resultPlayer[1] = oPlayer.getShootingRef().value;
                    console.log("🚀 ~ FindTargetPlayer ~ oPlayer:lose", oPlayer)
                    continue;
                }
            }

            if(resultPlayer[0] === null || resultPlayer[1] === null) {
                console.error("数据异常，没有枪手", opt)
            };

            return resultPlayer;
        }
    }

    function SpecialAnimation(specialCards){
        console.log("🚀特殊牌动画数据:", specialCards)
        return new Promise(async (resolve) => {
            for(let i = 0; i < specialCards.length; i++){
                const { player, pkResultData } = specialCards[i];
                const playerInfo = player.getPlayerInfo();

                player.setIsShowDetaulCard(true);

                SpecialAnimationRef.value.setCardsPosition(playerInfo.uid === UserInfo.value.uid ? "bottom" : player.seatDynamicPosition);
                SpecialAnimationRef.value.setRenderCards(pkResultData.OriginPkpData);
                SpecialAnimationRef.value.SetSuperTypeTxt(SuperTypeImg[pkResultData.super_type]);
                console.log("特殊牌牌型", pkResultData.super_type);
                SpecialAnimationRef.value.SetSpecialTypeNum(pkResultData.super_type);
                
                await new Promise(async (resolve) => {
                    setTimeout(async () => {
                        SpecialAnimationRef.value.isShow(true);
                        await SpecialAnimationRef.value.open();

                        // if(specialCards.length - 1 !== i){
                        await new Promise((resolve) => {
                            setTimeout(() => {
                                SpecialAnimationRef.value.isShow(false);
                                SpecialAnimationRef.value.clear()
                                setTimeout(() => resolve(true), 50)
                            }, 1400);
                        });
                        // }

                        resolve(true)
                    })
                })
                player.setIsShowDetaulCard(false);
            }

            resolve(true)
        })
    }

    async function HomeRunAnimation(HomeRunCard){
        return new Promise(async (resolve) => {
            for(let i = 0; i < HomeRunCard.length; i++) {
                const { player, pkResultData } = HomeRunCard[i];
                HomeRunAnimationRef.value.setRenderCards(pkResultData.OriginPkpData)

                await new Promise((resolve) => {
                    setTimeout(async() => {
                        await HomeRunAnimationRef.value.open();
                        setTimeout(() => {
                            HomeRunAnimationRef.value.clear();
                            resolve(true);
                        }, 1400)
                    })
                })
            }
            
            resolve(true)
        })
    }
    
    // 顺时针旋转动画
    function AnimationRotateClockwise(immediate = false){
        return new Promise((resolve) => {
            [...oPlayerRefs.value].forEach((player) => {
                const { idx, seatDynamicPosition, oPlayerBoxEl } = player;
                const otherDirection = findSeatNeighbors(seatDynamicPosition);
                const nextPosition = PlayerSeatPositions.value[otherDirection.next];
                
                // player.changePosition(otherDirection.next);
                player.setSeatDynamicPosition(otherDirection.next);

                requestAnimationFrame(() => {
                    oPlayerBoxEl.style.cssText = `
                        left: ${ nextPosition.left }px;
                        top: ${ nextPosition.top }px;
                        ${ immediate ? '' : 'transition: left 0.3s ease-in-out, top 0.3s ease-in-out;' }
                    `;
                })

                if(immediate){
                    resolve(true);
                } else {
                    setTransitionEnd(oPlayerBoxEl, (targetEl) => {
                        targetEl.style.zIndex = "0";
                        targetEl.style.transition = "";
                        resolve(true);
                    }, 300)
                }
            });
        })
    }

    // 逆时针旋转动画
    function AnimationRotateAntiClockwise(immediate = false){
        return new Promise((resolve, reject) => {
            [...oPlayerRefs.value].forEach((player) => {
                const { idx, seatDynamicPosition, oPlayerBoxEl } = player;
                const otherDirection = findSeatNeighbors(seatDynamicPosition);
                const prevPosition = PlayerSeatPositions.value[otherDirection.prev];

                // player.changePosition(otherDirection.prev);
                player.setSeatDynamicPosition(otherDirection.prev);

                requestAnimationFrame(() => {
                    oPlayerBoxEl.style.cssText = `
                        left: ${ prevPosition.left }px;
                        top: ${ prevPosition.top }px;
                        ${ immediate ? '' : 'transition: left 0.3s ease-in-out, top 0.3s ease-in-out;' }
                    `;
                })
                
                if(immediate){
                    resolve(true)
                } else {
                    setTransitionEnd(oPlayerBoxEl, (targetEl) => {
                        targetEl.style.zIndex = "0";
                        targetEl.style.transition = "";
                        resolve(true);
                    }, 300)
                }
            });
        });
    }

    function AnimationSwapPosition(immediate = false){

        function findNextPosition(position){
            switch(position){
                case "top":
                    return "bottom";
                case "bottom":
                    return "top";
                case "left":
                    return "right";
                case "right":
                    return "left"
            }
        }

        return new Promise((resolve, reject) => {
            console.log("🚀 ~ [...oPlayerRefs.value].:", oPlayerRefs.value);

            [...oPlayerRefs.value].forEach((player) => {
                console.log("🚀 ~ [...oPlayerRefs.value].forEach ~ player:", player)
                const { idx, seatDynamicPosition, oPlayerBoxEl } = player;
                const otherDirection = findNextPosition(seatDynamicPosition);
                const prevPosition = PlayerSeatPositions.value[otherDirection];
                console.log("初始化的目标位置", PlayerSeatPositions.value, prevPosition);
                

                // player.changePosition(otherDirection);
                player.setSeatDynamicPosition(otherDirection);

                requestAnimationFrame(() => {
                    oPlayerBoxEl.style.cssText = `
                        left: ${ prevPosition.left }px;
                        top: ${ prevPosition.top }px;
                        ${ immediate ? '' : 'transition: left 0.3s ease-in-out, top 0.3s ease-in-out;' }
                    `;
                })

                if(immediate){
                    resolve(true)
                } else {
                    setTransitionEnd(oPlayerBoxEl, (targetEl) => {
                        targetEl.style.zIndex = "0";
                        targetEl.style.transition = "";
                        resolve(true);
                    }, 300)
                }
            });
        });
    }

    return {
        RoomInfo,
        PlayerArray,
        IsRunPkpResultAnimate,
        LeaveUser,
        WatchUser,
        IsUserInDeskPlayer,
        PlayerSeatPositions,
        SpecialAnimationRef,
        HomeRunAnimationRef,
        ScoreRef,
        oPlayerDesk,    // 桌子元素
        oPlayerRefs,    // 玩家元素
        VsPkpTextRef,
        RoomGameStatus,
        IsShowPkResultDialog,
        RoomGameRound,
        IsShowSetUserActionComp,
        IsUseG,
        RoomUserNumber,

        SetRoomUserNumber,
        CacheInitPlayerPositionFn,
        SetIsUseG,
        InitRoomData,
        SetIsShowUserActionComp,
        PopCacheAnimationJoinDesk,
        PushCacheAnimationJoinDesk,
        JoinRoom,
        GetRoomIdByKid,
        LeaveRoom,
        RenderJoinDesk,
        RenderLeaveDesk,
        GetRoomInfo,
        UpdatePlayerArrayInfo,
        ComputedSeatPositions,
        PlayerPosRender,
        PlayerSeatStatusChange,
        SetRoomGameRound,
        PlayerOffLine, PlayerOnLine,
        // FindServerTargetPosition,
        GetImResourceTxtData,
        GetImResourceEmoteData,
        PlayerSendTxtMsg,
        PlayerSendEmoteMsg,
        FindIdxPlayerByServerPosition,
        FindIdxPlayerByUid,
        FindoPlayer,
        SetRoomGameStatus,
        SetIsShowPkResultDialog,

        AnimationRotateClockwise,
        AnimationRotateAntiClockwise,
        AnimationSwapPosition,

        AnimationRotatePkResult,
        
        _SetPlayerArrayInfo,
        _SetPlayerRefs,
        _SetRoomInfo,
    }

});

export function useRoomStoreHook(){
    return useRoomStore(store);
}


function findSeatNeighbors(position) {
    const index = SeatDirection.indexOf(position);  
    if (index === -1) {  
        return { prev: null, next: null };  
    }  
    
    const prev = index === 0 ? SeatDirection[SeatDirection.length - 1] : SeatDirection[index - 1];  
    const next = index === SeatDirection.length - 1 ? SeatDirection[0] : SeatDirection[index + 1];  
    
    return { prev, next };   
}

function computedPosition(opt){
    const { bgElem, playerElem } = opt;

    const bgRect = bgElem.getBoundingClientRect(),
         bgWidth = bgRect.width,  
         bgHeight = bgRect.height;
    
    const playerRect = playerElem.getBoundingClientRect(),
        playerWidth = playerRect.width,  
        playerHeight = playerRect.height;

    window.bgElem = bgElem;
    window.playerElem = playerElem;

    if(bgWidth === 0 ||
        bgHeight === 0 ||
        playerWidth === 0 ||
        playerHeight === 0
    ) {
        return null;
    }

    // 计算四个最佳位置  
    const bestPositions = {
        top: {  
            left: (bgWidth - playerWidth) / 2, 
            top: 0
        },  
        left: {  
            left: PaddingRomMargin,
            top: (bgHeight - playerHeight) / 2 - 22
        },  
        right: {
            left:(bgWidth - playerWidth) - PaddingRomMargin, 
            top: (bgHeight - playerHeight) / 2 - 22
        },
        bottom: {
            left:(bgWidth - playerWidth) / 2,
            top: bgHeight - playerHeight
        }  
    };  

    return bestPositions;
}