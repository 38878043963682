import { defineStore } from "pinia";
import store from "@/store"


export const useGlobalStore = defineStore("global", () => {
    const IsInLogin = ref(false);

    function SetIsInLogin(value){
        IsInLogin.value = value;
    }

    return {
        IsInLogin,
        SetIsInLogin
    }
})

export function useGlobalStoreHook(){
    return useGlobalStore(store);
}