import { defineStore } from "pinia";
import { ServerPkpFormat, PkpDataFormatDuns } from "@/utils";
import { judgeCardType, generateCardType, getCardValue } from '@/utils/pkpType'

import { useUserStore } from "./user";
import { computed, reactive, shallowRef } from "vue";
import { storeToRefs } from "pinia";
import { useRoomStore, ServerPosition, ClientPosition } from "./room";


const initRotaionsOpt = {
    rotationStep: 1,
    distanceTop: 0,
    distanceLeft: 0
};

const endRotationsOpt = {
    // rotationStep: 14,
    // distanceTop: 1.4,
    // distanceLeft: 1.5
    rotationStep: 9.1,
    distanceTop: 1.5,
    // distanceLeft: 6.4
    distanceLeft: 4.5

};

const specialInitRotationsOpt = {
    rotationStep: 17,
    distanceTop: 0,
    distanceLeft: -3
};

const specialEndRotationsOpt = {
    rotationStep: 3,
    distanceTop: 0,
    distanceLeft: -1
};

function getCardPositionsAndRotations(num, opt){
    const len = num;
    if(len % 2 === 0){
        throw Error("getCardPositionsAndRotations: pkpArray.length must be odd");
    }

    const { rotationStep, distanceTop, distanceLeft } = opt;

    let rotationArr = [],
        distanceTopArr = [],
        distanceLeftArr = [];
        
    for(let i = 0; i < (len - 1) / 2; i++){
        rotationArr[i] = (i + 1) * rotationStep;
        distanceLeftArr[i] = (i + 1) * distanceLeft;
        distanceTopArr[i] = ((i + 0.5) * -1) * distanceTop;
    }

    rotationArr = [...rotationArr.reverse().map((num) => -1 * num), 0, ...rotationArr.reverse()]
    distanceLeftArr = [...distanceLeftArr.reverse().map((num) => -1 * num), 0, ...distanceLeftArr.reverse()]
    distanceTopArr = [...distanceTopArr.reverse(), 0, ...distanceTopArr.reverse()]
    
    const resultArr = [];
    for(let i = 0; i < num; i++){
        resultArr.push({
            rotate: rotationArr[i],
            left: distanceLeftArr[i],
            top: distanceTopArr[i],
        })
    }

    return resultArr;
}

function GetCardPositionTranslate(len){
    if(len % 2 === 0){
        throw Error("getCardPositionsAndRotations: pkpArray.length must be odd");
    }

    let translateArr = [],
        distance = 40;

    for(let i = 0; i < (len - 1) / 2; i++){
        translateArr[i] = (i + 1) * distance;
        
    }
    translateArr = [...translateArr.reverse().map((num) => -1 * num), 0, ...translateArr.reverse()]
    return translateArr;
}

export const usePkpResultStore = defineStore("pkpResult", () => {

    const userStore = useUserStore(),
        { UserInfo } = storeToRefs(userStore);

    const roomStore = useRoomStore();

    const InitTouDunPosition = getCardPositionsAndRotations(3, initRotaionsOpt),
        InitZhongOrDiDunPosition = getCardPositionsAndRotations(5, initRotaionsOpt),
        TouDunXPosition = GetCardPositionTranslate(3),
        ZhongOrDiXPosition = GetCardPositionTranslate(5);
    
    const EndTouDunPosition = getCardPositionsAndRotations(3, endRotationsOpt),
        EndZhongOrDiDunPosition = getCardPositionsAndRotations(5, endRotationsOpt);

    const SpecialInitTouDunPosition = computed(() => getCardPositionsAndRotations(3, specialInitRotationsOpt)),
        SpecialInitZhongDunPosition = computed(() => getCardPositionsAndRotations(5, specialInitRotationsOpt)),
        SpecialEndTouDunPosition = computed(() => getCardPositionsAndRotations(3, specialEndRotationsOpt));

    const PlayerCards = shallowRef([]);
    const ShootResult = shallowRef([]);

    const ScoreResult = reactive({
        TouDunScore: 0,
        ZhongDunScore: 0,
        DiDunScore: 0,
        TotalScore: 0,
    });

    const PlayerTotalSource = ref({});

    const HasSuperCardNoUseScore = ref(false);
    const CachePlayerCards = reactive({
        ServerPosition: -1,
        OriginPkpData: null,
        AutoDunsPkpData: null
    })

    const IsInGameing = ref(false);

    // FormatPkResult({
    //     PkpResult: pk_result,
    // });

    function InitData() {
        PlayerCards.value = [];
        ShootResult.value = [];
        PlayerTotalSource.value = {};
        HasSuperCardNoUseScore.value = false;
        ScoreResult.TouDunScore = 0;
        ScoreResult.ZhongDunScore = 0;
        ScoreResult.DiDunScore = 0;
        ScoreResult.TotalScore = 0;
        CachePlayerCards.ServerPosition = -1;
        CachePlayerCards.OriginPkpData = null;
        CachePlayerCards.AutoDunsPkpData = null;
    }

    function GetPlayerCardByPosition(position){
        const target = PlayerCards.value.find((item) => {
            return ServerPosition[item.ServerPosition] == position;
        });

        if(target){
            return target;
        }

        if(position === ServerPosition?.[CachePlayerCards?.ServerPosition]){
            return CachePlayerCards
        }

        return null
    }

    function SetCachePlayerCardsByPosition(position, cards) {
        console.time("牌结果初始化计算")
        const OriginPkpData = ServerPkpFormat(cards);
        const AutoDunsPkpData = judgeCardType(PkpDataFormatDuns(OriginPkpData))
        console.timeEnd("牌结果初始化计算")
        
        CachePlayerCards.ServerPosition = position;
        CachePlayerCards.OriginPkpData = OriginPkpData;
        CachePlayerCards.AutoDunsPkpData = AutoDunsPkpData;
    }

    function FormatPkResult(opt){
        const { PkpResult, totalSource } = opt;
        console.log("🚀 ~ FormatPkResult ~ PkpResult:", PkpResult)
        ScoreResult.TouDunScore = 0;
        ScoreResult.ZhongDunScore = 0;
        ScoreResult.DiDunScore = 0;
        ScoreResult.TotalScore = 0;

        PlayerTotalSource.value = totalSource;

        PlayerCards.value = PkpResult.fulfill_result.all_cards.map((item) => {
            let OriginPkpData, AutoDunsPkpData;

            if(CachePlayerCards.ServerPosition === item.position){
                OriginPkpData = CachePlayerCards.OriginPkpData;
                AutoDunsPkpData = CachePlayerCards.AutoDunsPkpData;
            } else {
                console.time("牌结果初始化计算")
                OriginPkpData = ServerPkpFormat(item.cards);
                AutoDunsPkpData = judgeCardType(PkpDataFormatDuns(OriginPkpData))
                console.timeEnd("牌结果初始化计算")
            }

            if(HasSuperCardNoUseScore.value === false && item.super_type !== -1 && item.uid === UserInfo.value.uid){
                console.log(HasSuperCardNoUseScore.value === false , item.super_type !== -1 , item.uid === UserInfo.value.uid);
                HasSuperCardNoUseScore.value = true
            }
            
            return {
                ...item,
                ServerPosition: item.position,
                OriginPkpData,
                AutoDunsPkpData
            }
        });

        nextTick(() => {
            if(!Array.isArray(PkpResult.fulfill_result.fulfill_list) || !PkpResult.fulfill_result.fulfill_list){
                console.warn("比牌数据：fulfill_list 不是一个数组或没有数据", PkpResult.fulfill_result.fulfill_list);
                return;
            }

            let position = UserInfo.value.SeatInfo.position;

            if(!roomStore.IsUserInDeskPlayer){ // 观战玩家
                const targetPosition = roomStore.oPlayerRefs?.[0]?.position;
                console.log("观战玩家视角oPlayerRefs", roomStore.oPlayerRefs, targetPosition, ClientPosition[targetPosition]);
                position = ClientPosition[targetPosition]
            }

            ShootResult.value = PkpResult.fulfill_result.fulfill_list.filter((item) => {
                if(item.player_number_one === position
                    || item.player_number_two === position
                ){
                    const { pk_detail, player_number_one, player_number_two, all_shoot_number } = item;

                    const PlyerDunsKey = {};
                    PlyerDunsKey[player_number_one] = "one_cards_combination";
                    PlyerDunsKey[player_number_two] = "two_cards_combination";
                    
                    ScoreResult.TouDunScore += pk_detail.front_res[PlyerDunsKey[position]].normal_points
                    ScoreResult.ZhongDunScore += pk_detail.middle_res[PlyerDunsKey[position]].normal_points
                    ScoreResult.DiDunScore += pk_detail.behind_res[PlyerDunsKey[position]].normal_points
                }
                
                return true;
            });
        });

        IsInGameing.value = true;
    }

    return {
        IsInGameing,
        PlayerCards, ShootResult, ScoreResult, HasSuperCardNoUseScore, PlayerTotalSource,
        InitTouDunPosition, InitZhongOrDiDunPosition, EndTouDunPosition, EndZhongOrDiDunPosition, TouDunXPosition, ZhongOrDiXPosition,
        SpecialInitTouDunPosition, SpecialInitZhongDunPosition,
        SpecialEndTouDunPosition,
        GetPlayerCardByPosition, FormatPkResult, SetCachePlayerCardsByPosition, InitData
    }
})