import store from "@/store"
import CacheKey from "@/config/cache-key"
import { emailLogin, getUserInfoByUid, weChatLogin } from "@/api/login"
import { dynamicPublicForCdnUrl, delStorage, getStorage, setStorage } from "@/utils";
import { merge } from 'lodash-es'

export const useUserStore = defineStore("user", () => {

    const UserInfo = ref(getStorage(CacheKey.USER_INFO)),
        Token = ref(getStorage(CacheKey.TOKEN));


    async function EmailLogin(params){
        const { email, email_code } = params;

        let res = await emailLogin({
            email,
            email_code
        });

        if(res.code == 0){
            UserInfo.value = {
                uid: res.data.uid,
            };
            Token.value = res.data.session || -1;
            setStorage(CacheKey.USER_INFO, UserInfo.value);
            setStorage(CacheKey.TOKEN, Token.value);

            await GetUserInfoByUid()
        } 

        return res;
    }

    async function WeChatLogin(params){
        const { wechat_code } = params;

        let res = await weChatLogin({
            wechat_code
        });

        if(res.code == 0){
            UserInfo.value = {
                uid: res.data.uid,
            };
            Token.value = res.data.session || -1;
            setStorage(CacheKey.USER_INFO, UserInfo.value);
            setStorage(CacheKey.TOKEN, Token.value);

            await GetUserInfoByUid()
        } 

        return res;
    }

    async function GetUserInfoByUid(){
        const res = await getUserInfoByUid({
            uid: UserInfo.value.uid
        }).catch((err) => {
            console.error("查询用户信息失败")
        })

        const { room_ticket, user_account, user_decorate, user_wechat_info } = res?.data || {};

        UserInfo.value.ticketNum = room_ticket;
        UserInfo.value.nickName = user_decorate?.nickname;
        UserInfo.value.score = 0;
        UserInfo.value.avatarImg = user_decorate?.profile;

        setStorage(CacheKey.USER_INFO, UserInfo.value);
    }

    function loginOut(){
        UserInfo.value = null;
        Token.value = null;

        // delStorage(CacheKey.USER_INFO);
        // delStorage(CacheKey.TOKEN);

        localStorage.clear(); // 清空所有缓存
    }


    function UpdateUserInfo(value){
        UserInfo.value = merge(UserInfo.value, value)

        setStorage(CacheKey.USER_INFO, UserInfo.value);
    }

    return {
        UserInfo: UserInfo, 
        Token: Token,
        WeChatLogin,
        EmailLogin, loginOut, UpdateUserInfo,GetUserInfoByUid
    }
});


export function useUserStoreHook() {
    return useUserStore(store)
}