import router from "@/router"
import { storeToRefs } from "pinia";

import { useRoomStoreHook } from '@/store/modules/room'
import { useAudioStoreHook } from '@/store/modules/audio'
import { useUserStoreHook } from '@/store/modules/user'
import { usePkpStoreHook } from '@/store/modules/pkp'
import { useSocketStoreHook } from '@/store/modules/socket'
import { useGlobalStoreHook } from "@/store/modules/global";

import { RouterPrevLoad } from './preloadImg'

router.beforeEach(async (to, _form, next) => {
    const userStore = useUserStoreHook(),
        { UserInfo, Token } = storeToRefs(userStore);

    const pkpStore = usePkpStoreHook();
    const audioStore = useAudioStoreHook()
    const socketStore = useSocketStoreHook();
    const roomStore = useRoomStoreHook();
    const globalStore = useGlobalStoreHook(),
        { IsInLogin } = storeToRefs(globalStore);

    pkpStore.InitData()

    if(to.path !== "/room" && to.path !== "/"){
        if(to.meta && to.meta.title){
            document.title = to.meta.title;
        }

        roomStore.InitRoomData()
        socketStore.ClearKeepLive()
        audioStore.SetIsCanIUseYinYue(false)
        audioStore.AudioGameBgBGMAction(false);
        audioStore.SetIsCanIUseYinXiao(true)
    }

    if(IsInLogin.value && to.path === "/room" || to.path === "/"){
        audioStore.SetIsCanIUseYinYue(true)
        // audioStore.SetIsCanIUseYinYue(false)
        audioStore.SetIsCanIUseYinXiao(true)
    }

    if(to.path !== "/"){
        if(UserInfo.value && UserInfo.value.uid && Token.value){
            RouterPrevLoad({
                UserInfo
            });
        }
    }
    
    if(to.path !== "/wxcallback" && to.path !== "/login"){
        if(UserInfo.value && Token.value){
            await socketStore.InitSocket().catch((err) => { console.log(err) });
        }
    }

    if(to.path === "/test"){
        const viewport = document.getElementById("viewport");
        viewport.content = "width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=1.0,user-scalable=yes";
    } else {
        const viewport = document.getElementById("viewport");
        viewport.content = "width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=0,user-scalable=no";
    }

    if(to.path === "/" || to.path === "/wxcallback" || to.path === "/hallloading" || to.path === "/login" || to.path === "/test" || to.path === "/upload"){
        next()
    } else {
        console.log("IsInLogin.value && UserInfo.value && Token.value", (IsInLogin.value && UserInfo.value && Token.value), IsInLogin.value , UserInfo.value, Token.value);
        console.log("path", to);
        if(IsInLogin.value && UserInfo.value && Token.value){
            next()
        } else {
            next("/")
        }
    }

});

// router.afterEach(async (to, _form, next) => {
    // const userStore = useUserStoreHook(),
    //     { UserInfo, Token } = storeToRefs(userStore);

    // const socketStore = useSocketStoreHook();
    // const globalStore = useGlobalStoreHook(),
    //     { IsInLogin } = storeToRefs(globalStore);
    
    // if(to.path !== "/wxcallback" && to.path !== "/login"){
    //     if(IsInLogin.value && UserInfo.value && Token.value){
    //         await socketStore.InitSocket();
    //     }
    // }
// })