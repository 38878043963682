import store from "@/store"
import { defineStore, storeToRefs } from "pinia";
import { heartKeepLive, agianSendCard } from "@/api/room"
import { ServerPkpFormat, PkpDataFormatDuns, timer16ByWorker } from "@/utils";
import workerTimer from '@/utils/intervalWorker'

import { getSocketToken } from "@/api/socket"
import { useSocket } from "@/utils/socket";
import { useAudioStore } from "@/store/modules/audio"
import { ShowJoinUserNotice } from "@/libs/components/JoinUserNotice";

import { usePkpStore } from "./pkp";
import { usePkpResultStore } from "./pkpresult";
import { useUserStoreHook } from "./user";
import { useRoomStoreHook, useRoomStore, ServerPosition } from "./room";

import { useRouter, useRoute } from 'vue-router'
import { computed, nextTick } from "vue";


// 针对在房用户 心跳检测
const KeepLiveMap = new Map();

const Keep_Live_time = 6000;
// const Keep_Live_WsMsg_time = 60 * 1000;
const Keep_Live_WsMsg_time = 6000;

let connectTimer = null;

export const useSocketStore = defineStore("socket", () => {
    const roomStore = useRoomStoreHook(),
        userStore = useUserStoreHook(),
        pkpStore = usePkpStore(),
        pkpResultStore = usePkpResultStore();

    const router = useRouter();
    const route = useRoute();
    const audioStore = useAudioStore();

    const { UserInfo, Token } = storeToRefs(userStore);
    const socketRef = ref();
    const Keep_Live_Timer = ref(null),
        Keep_Live_WsMsg_Timer = ref(null);

    const IsInConnectSocket = computed(() => {
        return (socketRef.value && (
            socketRef.value.readyState === socketRef.value.CONNECTING ||
            socketRef.value.readyState === socketRef.value.OPEN
        )) ? true : false
    });

    const IsLoadingEnd = ref(false);

    const JoinRoomUserInfo = ref({})

    function InitSocket(){
        return new Promise(async (resolve, reject) => {
            if(!UserInfo?.value?.uid || !Token.value) {
                console.error("未登录，无法进行ws"); 
                reject("未登录，无法进行ws")
                return;
            }
    
            if(IsInConnectSocket.value) {
                console.warn("ws 正在链接 或  已连接成功..., 不需要再次链接")
                reject("ws 正在链接 或  已连接成功..., 不需要再次链接")
                return;
            }
    
            const ws_token = await GetSocketToken()
            console.log("ws_token", ws_token);
    
            const options = {
                path: `/ws/socket/io/register`,
                query: {
                    uid: UserInfo.value.uid,
                    session: Token.value,
                    ws_token,
                },
            };
    
            connectTimer = setTimeout(() => {
                connectTimer && clearTimeout(connectTimer)
                connectTimer = null;
                
                socketRef.value = useSocket(options);
    
                socketRef.value.subscribe('open', () => {
                    console.log('WebSocket连接成功!', socketRef)
                    WsKeepLive()
    
                    nextTick(() => {
                        console.log("是否是房间", route, route.path, route.path == "/", roomStore.RoomInfo)
                        if("/" === router.currentRoute.value.path && roomStore.RoomInfo.id){
                            setTimeout(async () => {
                                
                                // await roomStore.JoinRoom({
                                //     uid: UserInfo.value.uid, 
                                //     room_id: roomStore.RoomInfo.id,
                                // });
    
                                // keepLive();
                                // console.log("进入房间 socket 链接后 查询房间信息");
                            }, 500)
                        }
                    })
                    resolve(true)
                    // const greet = 'ping'
                    // 发送打招呼消息
    
                    // setInterval(() => {
                    //     socketRef.value.sendMessage(greet)
                    // }, 5000)
                    // console.log("发送消息", greet);
                })
    
                socketRef.value.subscribe('close', reason => {
                    console.error('WebSocket连接关闭! - 停止心跳任务', reason)
                    console.warn("测试中，已经将停止定时任务逻辑注释掉了");
                    ClearWsKeepLive()
                    ClearKeepLive();
                })
    
                socketRef.value.subscribe('message', result => {
                    const decoder = new TextDecoder("utf-8");
                    const jsonString = decoder.decode(new Uint8Array(result));
                    const jsonObj = JSON.parse(jsonString);
    
                    const { payload } = jsonObj;
                    const { tp, room_info } = payload?.link_msg || {};
                    
                    console.log("🚀 ~ socketMessage ~ tp socket 事件:", tp, room_info)
                    console.log('WebSocket接收到消息:', jsonObj)
                    if(room_info){
                        if(roomStore.RoomInfo.id === room_info.id){
                            if(jsonObj.ev) { // 存在就处理 事件
                                socketMessage(jsonObj);
                            }
                        } else {
                            console.error("房间号不一致 => tp房间号, 用户房间号", room_info.id, roomStore.RoomInfo.id, roomStore.RoomInfo.id === room_info.id)
                        }
                    } else {
                        console.error("tp事件没有 room_info, 不能校验房间号");
                    }
                })
    
                socketRef.value.subscribe('error', err => {
                    console.log('WebSocket捕获错误:', err)
                })
    
                socketRef.value.subscribe('reconnect', _socket => {
                    console.log('WebSocket断开重连:', _socket)
                    socketRef.value = _socket
                })
                
            }, 1000)
        })
        
    }

    async function GetSocketToken(){
        const res = await getSocketToken({
            user_id: String(UserInfo.value.uid),
            session: Token.value,
        });
        
        if(!res || res?.code !== 0){
            console.error("获取房间接口有误")
            return
        }

        return res.data.ws_token;
    }


    function WsKeepLive(){
        socketRef.value.sendMessage("ping")
        console.log(`Ws发送消息 - ping`, Date.now(), Date().toString());

        Keep_Live_WsMsg_Timer.value = workerTimer.setInterval(async() => {
            socketRef.value.sendMessage("ping")
            console.log(`Ws发送消息 - ${Keep_Live_WsMsg_Timer.value}`, Date.now(), Date().toString());
        }, Keep_Live_WsMsg_time);

        KeepLiveMap.set(Keep_Live_WsMsg_Timer.value, "ping");
    }

    function ClearWsKeepLive(){
        console.log("清除Ws心跳计时器", Keep_Live_WsMsg_Timer.value);
        KeepLiveMap.delete(Keep_Live_WsMsg_Timer.value);
        console.log("清除Ws心跳计时器KeppLiveMap", KeepLiveMap);

        workerTimer.clearInterval(Keep_Live_WsMsg_Timer.value)
        Keep_Live_WsMsg_Timer.value = null;
    }

    async function keepLive(){
        if(!UserInfo?.value?.uid || !Token.value) {
            console.error("未登录，无法进行ws"); 
            return;
        }
        console.log("心跳检测判断", Keep_Live_Timer.value);
        
        if(Keep_Live_Timer.value !== null){
            console.warn("心跳检测已开启"); 
            return;
        }

        const oneRes = await heartKeepLive({
            uid: UserInfo.value.uid,
            room_id: roomStore.RoomInfo.id,
        });
        console.log("心跳结果", oneRes, Date.now(), Date().toString());

        Keep_Live_Timer.value = workerTimer.setInterval(async() => {
            const res = await heartKeepLive({
                uid: UserInfo.value.uid,
                room_id: roomStore.RoomInfo.id,
            }).catch((err) => {
                console.log("心跳报错", err);
            });
            
            console.log(`心跳结果 - ${Keep_Live_Timer.value}`, res, Date.now(), Date().toString());
        }, Keep_Live_time);

        KeepLiveMap.set(Keep_Live_Timer.value, {
            uid: UserInfo.value.uid,
            room_id: roomStore.RoomInfo.id,
        });
        console.log("添加心跳计时器KeppLiveMap", KeepLiveMap);
        
    }

    function ClearKeepLive(){
        console.log("清除心跳计时器", Keep_Live_Timer.value);
        KeepLiveMap.delete(Keep_Live_Timer.value);
        console.log("清除心跳计时器KeppLiveMap", KeepLiveMap);

        workerTimer.clearInterval(Keep_Live_Timer.value)
        Keep_Live_Timer.value = null;
    }

    // socket 事件处理 //
    // const (
    // CreateRoom              WaterRawMsgType = "create_room"                // 创建房间
    // JoinRoom                WaterRawMsgType = "join_room"                  // 进入房间
    // LeaveRoom               WaterRawMsgType = "leave_room"                 // 离开房间
    // JoinRoomPlayer          WaterRawMsgType = "join_room_player"           // 上桌
    // LeaveRoomPlayer         WaterRawMsgType = "leave_room_player"          // 下桌(移除桌位图标)
    // RoomImPlayerLeave       WaterRawMsgType = "room_player_leave"          // 房间im消息用户离线 (玩家离开，保留桌位图标)
    // RoomPlayerNotReady      WaterRawMsgType = "room_player_not_ready"      // 玩家未准备
    // RoomPlayerReady         WaterRawMsgType = "room_player_ready"          // 玩家准备
    // RoomPlayerDealingCards  WaterRawMsgType = "room_player_dealing_cards"  // 玩家发牌中
    // RoomPlayerSortingCards  WaterRawMsgType = "room_player_sorting_cards"  // 玩家理牌
    // RoomPlayerConfirm       WaterRawMsgType = "room_player_confirm"        // 玩家确认出牌
    // RoomPlayerResultDisplay WaterRawMsgType = "room_player_result_display" // 查看展示结果
    // RoomImMessage           WaterRawMsgType = "room_im_message"            // 房间im消息
    // )
    const _SOCKETEVENT = {
        join_room: async (JsonData) => {
            const { payload } = JsonData;
            const { link_msg } = payload;
            const { room_info, user_info, index: gameRound, room_cnt } = link_msg;

            roomStore._SetRoomInfo(room_info);
            console.log("room_info.room_status", room_info.room_status);
            
            switch(room_info.room_status){
                case 0: // 关闭房间
                    setTimeout(() => {
                        router.replace({ path: "/error", query: {
                            errorCode: ErrorCodeNumber.CloseRoom,
                        }});
                    }, 50);
                    break;
                case 1: // 房间开启中

                    break;
                case 2: // 游戏结束
                    console.log("游戏结束", router, router.replace);
                    setTimeout(() => {
                        router.replace({ path: "/over", query: {
                            kid: Number(roomStore.RoomInfo.id)
                        }});
                    }, 50);
                    console.log("游戏结束跳转");
                    return;
            }

            roomStore.SetRoomGameRound(gameRound);
            roomStore.SetRoomUserNumber(room_cnt);

            keepLive();
            userStore.UpdateUserInfo({
                status: 0,
            })

            console.log("加入房间的房间状态", user_info.user_account.uid, UserInfo.value.uid, roomStore.RoomGameStatus);

            if(user_info.user_account.uid === UserInfo.value.uid){
                roomStore.SetRoomGameStatus(0)

                const res = await roomStore.GetRoomInfo();
                roomStore.UpdatePlayerArrayInfo({
                    players: res.data.players,
                    now_process: res.data.now_process,
                })
                
                const IsHasStauts3 = Object.values(res.data.players).some((item) => item.status === 3);
                // 如果有状态3
                console.log("用户加入房间情况", UserInfo.value === 3, IsHasStauts3);

                console.log("UserInfo.value.status === 4 && !IsHasStauts3 && !roomStore.IsUserInDeskPlayer", UserInfo.value.status, !IsHasStauts3, roomStore.IsUserInDeskPlayer, res.data.players);
                
                if(UserInfo.value.status === 3 || IsHasStauts3){// 如果是理牌阶段刷新
                    await agianSendCard({
                        uid: UserInfo.value.uid,
                        room_id: roomStore.RoomInfo.id
                    })

                    const time = room_info.room_introduction.organize_cards_times - (Math.ceil((Date.now() / 1000)) - res.data.now_process.send_cards_time);

                    console.log("倒计时time", time, room_info.room_introduction.organize_cards_times, Math.ceil((Date.now() / 1000)), res.data.now_process.send_cards_time);
                    pkpStore.setDefaultTime(time);
                    pkpStore.ProgressTimeRun();

                    if(!roomStore.IsUserInDeskPlayer){ // 观战玩家
                        roomStore.SetRoomGameStatus(3);
                        
                        roomStore.oPlayerRefs.forEach((player) => {
                            if(player.GameStatus === 4){
                                setTimeout(() => {
                                    const resultRef = player.getPkpResultRef().value;
                                    resultRef.isShow(true);
                                    setTimeout(() => {
                                        resultRef.Expand()
                                    }, 50)
                                }, 50)
                            }
                        });
                    }
                    // 如果玩家状态是4，且没有玩家是状态3（动画阶段），并且不是观战用户则异常按钮显示
                    // 多加一个状态5.因为后端并没有动画阶段的数值。
                } else if((
                    // UserInfo.value.status === 4 || 
                    UserInfo.value.status === 5) && !IsHasStauts3 && roomStore.IsUserInDeskPlayer){ // 如果是玩家动画阶段刷新
                    // setTimeout(() => { // 异常显示按钮
                    //     const oPlayer = roomStore.FindoPlayer({
                    //         uid: user_info.user_account.uid
                    //     });
                    //     if(oPlayer){
                    //         oPlayer.setIsShowReadyPlayBtn(true);
                    //     }
                    // }, 50)
                } else if (!IsHasStauts3 && !roomStore.IsUserInDeskPlayer){ // 如果是游客动画阶段刷新
                    const last_finish_process = res.data.last_finish_process;
                    if(last_finish_process.status !== 0 && last_finish_process.id !== 0){
                        // 异常显示按钮逻辑修改成直接显示结果
                        const pkpData = JSON.parse(res.data.last_finish_process.pk_result);
                        console.log("🚀 用户比牌结果数据:", pkpData)
                        pkpResultStore.FormatPkResult({
                            PkpResult: pkpData,
                        });
                        // roomStore.oPlayerRefs.forEach((player) => {
                        //     player.setGamePlayerStatus(4);
                        // });
                        // roomStore.SetRoomGameStatus(4);
                        // userStore.UpdateUserInfo({
                        //     status: 4,
                        // })

                        roomStore.SetIsShowPkResultDialog(true);
                        // setTimeout(() => {
                        //     roomStore.AnimationRotatePkResult()
                        // }, 1000)
                    }
                }

                IsLoadingEnd.value = true; // 加载完毕
            } else {
                if(roomStore.RoomGameStatus !== 4){ // 动画四阶段，不给玩家恢复上线状态。其他状态都行
                    roomStore.PlayerOnLine({
                        user_info
                    })
                }
            }

            if(roomStore.IsShowSetUserActionComp === true){ // 如果用户信息一直打开
                roomStore.GetRoomInfo();
            }
            
            ShowJoinUserNotice(`${user_info?.user_decorate?.nickname}进入房间`, 6000)
        },

        leave_room: (JsonData) => {
            const { payload } = JsonData;
            const { link_msg } = payload;
            const { room_info, user_info, room_cnt } = link_msg;

            console.log("socket 链接后 leave_room 事件： 查询房间信息");
            roomStore.SetRoomUserNumber(room_cnt);

            if(roomStore.RoomGameStatus == 4){ // 不让用户下桌，只进入离线状态
                console.warn("动画阶段离房拦截")
                return;
            }

            roomStore._SetRoomInfo(room_info);
            if(roomStore.IsShowSetUserActionComp === true){ // 如果用户信息一直打开
                roomStore.GetRoomInfo();
            }
        },

        join_room_player:(JsonData) => {
            const { payload } = JsonData;
            const { link_msg } = payload;
            const { room_info, user_info, score } = link_msg;
            console.log("socket 链接后 join_room_player 事件： 查询房间信息");

            roomStore._SetRoomInfo(room_info);

            // console.log("上桌-动画状态", roomStore.RoomGameStatus);
            
            if(roomStore.RoomGameStatus == 4){
                console.warn("动画阶段上桌拦截")
                roomStore.PushCacheAnimationJoinDesk(link_msg)
                return;
            }

            socketSetPlayerSeat({
                tp: "join_room_player",
                position: link_msg.position,
                user_info,
                score
            })

            if(roomStore.IsShowSetUserActionComp === true){ // 如果用户信息一直打开
                roomStore.GetRoomInfo();
            }
        },

        leave_room_player: (JsonData) => {
            const { payload } = JsonData;
            const { link_msg } = payload;
            const { room_info, user_info } = link_msg;

            roomStore._SetRoomInfo(room_info);

            if(roomStore.RoomGameStatus == 4){
                console.warn("动画阶段下桌拦截")
                roomStore.PopCacheAnimationJoinDesk(link_msg)
                roomStore.PlayerOffLine({
                    user_info
                })
                return;
            }

            socketSetPlayerSeat({
                tp: "leave_room_player",
                position: link_msg.position,
                user_info,
            })
            
            if(roomStore.IsShowSetUserActionComp === true){ // 如果用户信息一直打开
                roomStore.GetRoomInfo();
            }
        },
        
        room_player_ready: (JsonData) => {
            const { payload } = JsonData;
            const { link_msg } = payload;
            const { room_info, user_info } = link_msg;
            console.warn("tp时间准备-当前时间：", Date.now(), Date().toString())
            const oPlayer = roomStore.FindoPlayer({
                uid: user_info.user_account.uid
            });
            
            if(!oPlayer){
                console.warn("状态更新监听: 查无此人"); return;
            }

            if(UserInfo.value.uid === user_info.user_account.uid){
                roomStore.SetIsShowPkResultDialog(false);
            }

            if(roomStore.RoomGameStatus == 4){ // 不让用户准备，影响动画内容
                console.warn("动画阶段离房拦截")
                oPlayer.setIsAnimationReady(true);
                return;
            }

            roomStore.SetRoomGameStatus(1);
            oPlayer.setGamePlayerStatus(1);
            oPlayer.SetIsShowReadyPlayBtn(false);
            audioStore.AudioReadyBtnBGM()
            // userStore.UpdateUserInfo({
            //     status: 1,
            // });
        },

        room_im_message: (JsonData) => {
            const { payload } = JsonData;
            const { link_msg } = payload;
            const { message, user_info } = link_msg;

            if(message.type === 1){
                roomStore.PlayerSendTxtMsg({
                    uid: user_info.user_account.uid,
                    txt: message.value,
                    audioUrl: message.voice
                })
            } else if(message.type === 2) {
                roomStore.PlayerSendEmoteMsg({
                    uid: user_info.user_account.uid,
                    imgUrl: message.value,
                })
            }
            
        },

        room_player_dealing_cards: (JsonData) => {
            const { payload } = JsonData;
            const { link_msg } = payload;
            const { message, user_info, player_status, index: gameRound } = link_msg;

            console.warn("tp发牌-当前时间：", Date.now(), Date().toString())

            roomStore.SetIsShowPkResultDialog(false);
            roomStore.SetRoomGameRound(gameRound);
            // ServerPkpFormat

            // 所有人状态变更 3
            roomStore.SetRoomGameStatus(3);
            console.log("房间状态",roomStore.RoomGameStatus);
            
            roomStore.oPlayerRefs.forEach((player) => {
                if(player.GameStatus.value != 4){
                    player.setGamePlayerStatus(3);
                }
            });
            
            console.log("用户发牌数据", link_msg);
            const send_cards_time = roomStore.RoomInfo.room_introduction.organize_cards_times - (Math.ceil((Date.now() / 1000)) - link_msg.send_card.send_cards_time);
            console.log("倒计时time", send_cards_time, roomStore.RoomInfo.room_introduction.organize_cards_times, Math.ceil((Date.now() / 1000)), link_msg.send_card.send_cards_time);

            if(UserInfo.value.status == 4){ // 用户已经理牌完毕
                
                pkpResultStore.SetCachePlayerCardsByPosition(UserInfo.value?.SeatInfo?.position, link_msg.send_card.card.cards);

                pkpStore.setDefaultTime(send_cards_time);
                pkpStore.ProgressTimeRun();

                setTimeout(() => {
                    const oPlayer = roomStore.FindoPlayer({
                        uid: UserInfo.value.uid
                    });

                    console.log("用户理牌完成逻辑", oPlayer);
                    
                    oPlayer.setGamePlayerStatus(4);
                    pkpStore.SetIsShowPkpArrangeDialog(false);
                    pkpStore.SetIsShowPkpArrange(false);

                    setTimeout(() => {
                        const resultRef = oPlayer.getPkpResultRef().value;
                        resultRef.isShow(true);
                        setTimeout(() => {
                            resultRef.Expand()
                        }, 50)
                    }, 50)
                }, 300)

                return
            }
            
            console.log("用户没有理牌完成逻辑");
            userStore.UpdateUserInfo({
                status: 3,
            })
            pkpStore.SetServerPkp({
                pkpData: link_msg.send_card.card.cards,
                combinationList: link_msg.send_card.card?.combination_list,
                superCardsType: link_msg.send_card.card?.super_cards_type,
                time: send_cards_time,
            });
        },
        room_player_sorting_cards: (JsonData) => {
            const { payload } = JsonData;
            const { link_msg } = payload;
            const { room_info, user_info, send_cards_time, index: gameRound, player_status } = link_msg;

            console.log("观战玩家理牌事件", roomStore.IsUserInDeskPlayer);
            if(!roomStore.IsUserInDeskPlayer){ // 观战玩家
                roomStore.SetIsShowPkResultDialog(false);
                roomStore.SetRoomGameStatus(3);
                roomStore.SetRoomGameRound(gameRound);

                console.log("房间状态",roomStore.RoomGameStatus);
                roomStore.oPlayerRefs.forEach((player) => {
                    if(player.GameStatus.value != 4){
                        player.setGamePlayerStatus(3);
                    }
                });
                
                const time = roomStore.RoomInfo.room_introduction.organize_cards_times - (Math.ceil((Date.now() / 1000)) - send_cards_time);
                console.log("倒计时time", time, send_cards_time, roomStore.RoomInfo.room_introduction.organize_cards_times, Math.ceil((Date.now() / 1000)));
                pkpStore.setDefaultTime(time);
                pkpStore.ProgressTimeRun();
            }

            // const oPlayer = roomStore.FindoPlayer({
            //     uid: user_info.user_account.uid
            // });
            
            // if(!oPlayer){
            //     console.warn("状态更新监听: 查无此人"); return;
            // }

            // oPlayer.setGamePlayerStatus(player_status);

        },
        room_player_leave: (JsonData) => {
            const { payload } = JsonData;
            const { link_msg } = payload;
            const { message, user_info, position } = link_msg;
            
            console.log("离线通知：", link_msg);


            roomStore.PlayerOffLine({
                user_info
            })
        },
        room_player_confirm: (JsonData) => {
            const { payload } = JsonData;
            const { link_msg } = payload;
            const { message, user_info, player_status } = link_msg;

            const oPlayer = roomStore.FindoPlayer({
                uid: user_info.user_account.uid
            });
            
            if(!oPlayer){
                console.warn("状态更新监听: 查无此人"); return;
            }

            oPlayer.setGamePlayerStatus(4);
            audioStore.AudioHasPlayerEndArrageBGM();
            
            setTimeout(() => {
                const resultRef = oPlayer.getPkpResultRef().value;
                console.log("🚀 ~ handleSendPkp ~ resultRef:", resultRef)
                resultRef.isShow(true);
                setTimeout(() => {
                    resultRef.Expand()
                }, 100)
            }, 100)
        },
        room_player_result_display: (JsonData) => {
            const { payload } = JsonData;
            const { link_msg } = payload;
            const { message, pk_result, total_source } = link_msg;
            
            roomStore.SetIsShowPkResultDialog(false);
            pkpStore.SetIsShowPkpArrangeDialog(false);

            const pkpData = JSON.parse(pk_result);
            console.log("🚀 用户比牌结果数据:", pkpData)
            pkpResultStore.FormatPkResult({
                PkpResult: pkpData,
                totalSource: total_source,
            });
            
        
            roomStore.oPlayerRefs.forEach((player) => {
                player.setGamePlayerStatus(4);
            });
            roomStore.SetRoomGameStatus(4);
            userStore.UpdateUserInfo({
                status: 4,
            })

            setTimeout(() => {
                roomStore.AnimationRotatePkResult()
            }, 500)

        }
    }

    function socketMessage(JsonData){
        const { payload } = JsonData;
        const { link_msg } = payload;
        const { tp } = link_msg;

        if(!_SOCKETEVENT[tp]){
            console.error("找不到 socket 事件方法")
            return;
        }
        
        _SOCKETEVENT[tp](JsonData);
    }

    function socketSetPlayerSeat(opt){
        const { tp, position, user_info, score } = opt;
        const { user_decorate } = user_info;

        const idx = roomStore.FindIdxPlayerByServerPosition(position)

        if(tp === "join_room_player"){

            if(idx === -1){ // 位置上已经有人了
                console.warn("join_room_player 监听，位置上已经有人了，不需要上桌", opt);
                return;
            }

            if(UserInfo.value.uid === user_info?.user_account?.uid){ // 用户本人坐下
                console.warn("join_room_player 监听，用户本人已坐下，不需要上桌", opt);
                roomStore._SetPlayerArrayInfo(idx, {
                    ServerInfo: {
                        "position": position,
                        "status": 0,
                        "user_info": user_info,
                    }
                });

                setTimeout(() => {
                    setScore(user_info.user_account.uid, score);
                }, 30)
                return;
            }
    
            roomStore.RenderJoinDesk({
                idx,
                position,
            }, {
                uid: user_info.user_account.uid,
                ServerInfo: user_info,
                IsSitDown: true,
                score: score || 0,  // 分数  服务器没有给分数
                nickName: user_decorate.nickname,
                avatarImg: user_decorate.profile,
            });


            function setScore(uid, score){
                const oPlayer = roomStore.FindoPlayer({
                    uid
                });
                
                if(!oPlayer){
                    console.warn("状态更新监听: 查无此人"); return;
                }

                oPlayer.setGameScore(score)
            }

        } else if(tp === "leave_room_player") { // 下桌

            if(idx === -1){
                console.warn("leave_room_player 监听，不需要下桌", opt);
                return;
            }

            roomStore.RenderLeaveDesk({
                idx,
                position,
                user_decorate
            });
        }

        
    }

    return {
        IsInConnectSocket,
        JoinRoomUserInfo,
        IsLoadingEnd,
        Keep_Live_Timer,

        InitSocket,
        ClearKeepLive,
    }
})


export function useSocketStoreHook() {
    return useSocketStore(store)
}